import React, { useState, useEffect, useContext } from 'react';
// import './trade.css'
import withAuth from '../ProtectionHOCs/withAuth';
import "./on-board.css"
import { useDispatch, useSelector } from 'react-redux';
import { clearKeys, connectExchanger, disconnectExchanger, handleApiKeyChanges, startBot as controlBot, stopBot as handleStopBot } from '../../redux/slice/userSlice';
import { StateContext } from './StateContext';
import axios from 'axios';

function Trade() {
  const dispatch = useDispatch();

  const [accountInfo, setAccountInfo] = useState(null);
  const [apiKey, setApiKey] = useState('');
  const [apiSecretKey, setApiSecretKey] = useState('');
  const [exchangeType, setExchangeType] = useState('binanceFutures');
  const [tradeDecision, setTradeDecision] = useState(null);
  const [futuresAssets, setFuturesAssets] = useState([]);
  const [tradingPairs, setTradingPairs] = useState([]);
  const [tradingManual, setTradingManual] = useState("automatic");
  const [orderType, setOrderType] = useState('market');
  const [selectedPair, setSelectedPair] = useState('KSMUSDT');
  const [showAccountInfo, setShowAccountInfo] = useState(false);
  const [orderTypes, setOrderTypes] = useState(['market', 'limit', 'trailing']);
  const [tradeResult, setTradeResult] = useState(null);
  const [tradeResultVisible, setTradeResultVisible] = useState(false);
  const [symbol, setSymbol] = useState('');
  const isConnected = useSelector((state) => state.user.connected);
  const isBotRunning = useSelector((state) => state.user.botRunning);
  const accId = useSelector((state) => state.user.accountId);
  const apiSecKey = useSelector((state) => state.user.apiSecretKey);
  const api_Key = useSelector((state) => state.user.apiKey);
  const { state, setState } = useContext(StateContext);
  const [isExecutingTrade, setIsExecutingTrade] = useState(false);
  const [openOrders, setOpenOrders] = useState([]);
  const [openPositions, setOpenPositions] = useState([]);

   

  const handleClearKeys = (e) => {
    e.preventDefault();
    dispatch(clearKeys());
  }

  const startBot = () => {
    dispatch(controlBot());
  };

  const stopBot = () => {
    dispatch(handleStopBot());
    setAccountInfo(null);
    setTradeDecision(null)
  };

  const handleAutomaticTrade = () => {
    setTradingManual("automatic");
    fetchAutomaticTradingPairs();
  }

  const handleManualTrade = () => {
    setTradingManual("manual");
    setSymbol(selectedPair);
  }

  const connectExchange = async () => {
    dispatch(handleApiKeyChanges({ apiKey: !api_Key?.toString().trim() ? apiKey : api_Key, apiSecretKey: !apiSecKey?.toString().trim() ? apiSecretKey : apiSecKey }))
    try {
      const response = await fetch(process.env.NODE_ENV == 'production' ? 'https://freedombot.online/api/set-api-keys' : 'http://localhost:8001/api/set-api-keys', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          exchangeType: exchangeType === 'binanceFutures' ? 'binancefutures' : 'binance',
          apiKey: !api_Key?.trim() ? apiKey : api_Key,
          apiSecretKey: !apiSecKey.trim() ? apiSecretKey : apiSecKey
        })
      });
      if (!response.ok) {
        throw new Error('Failed to connect to exchange');
      }
      const data = await response.json();

      dispatch(connectExchanger({ accountId: data.accountId }))
    } catch (error) {
      console.error('Error connecting to exchange:', error);
      dispatch(disconnectExchanger())
    }
  };


  const disconnectExchange = () => {
    dispatch(disconnectExchanger())
    setAccountInfo(null);
    setTradeDecision(null);
  };




  const fetchAutomaticTradingPairs = async () => {
    try {
      const response = await axios.get('https://fapi.binance.com/fapi/v1/exchangeInfo');
      const automatedPairs = response.data.symbols.map(symbol => symbol.symbol);

      const volatilityPromises = automatedPairs.map(async pair => {
        try {
          const res = await axios.get(
            `https://fapi.binance.com/fapi/v1/klines?symbol=${pair}&interval=1h&limit=4`
          );
          const klines = res.data;
          const prices = klines.map(kline => parseFloat(kline[4])); // Closing prices
          const maxPrice = Math.max(...prices);
          const minPrice = Math.min(...prices);
          const volatility = (maxPrice - minPrice) / minPrice;
          return { pair, volatility };
        } catch (error) {
          console.error(`Error fetching klines for pair ${pair}:`, error);
          return { pair, volatility: 0 };
        }
      });

      const volatilityData = await Promise.all(volatilityPromises);

      const mostVolatilePair = volatilityData.reduce((prev, current) =>
        current.volatility > prev.volatility ? current : prev
      );

      setState(prevState => ({
        ...prevState,
        selectedPair: mostVolatilePair.pair,
        symbol: mostVolatilePair.pair,
        automated_pairs: mostVolatilePair.pair,
      }));
      setSymbol(mostVolatilePair.pair);
      setSelectedPair(mostVolatilePair.pair);
      console.log('Most Volatile Pair:', mostVolatilePair.pair);
    } catch (error) {
      console.error('Error fetching automated trading pairs:', error);
    }
  };
  useEffect(() => {
    const fetchAccountInfo = async () => {
      try {
        const response = await fetch(process.env.NODE_ENV == 'production' ? 'https://freedombot.online/api/usdt-balance' : 'http://localhost:8001/api/usdt-balance', {
          headers: {
            'X-API-KEY': api_Key,
            'X-API-SECRET-KEY': apiSecKey
          }
        });
        if (!response.ok) {
          throw new Error('Error fetching account info');
        }
        const data = await response.json();
        setAccountInfo(data.usdtBalance);
      } catch (error) {
        console.error('Error fetching account info:', error);
        setAccountInfo(null);
      }
    };

    const fetchTradeDecision = async () => {
      try {
        const response = await fetch(process.env.NODE_ENV == 'production' ? `https://freedombot.online/api/trade-decision?symbol=${selectedPair}` : `http://localhost:8001/api/trade-decision?symbol=${selectedPair}`);
        if (!response.ok) {
          throw new Error('Failed to fetch trading decision');
        }
        const { decision } = await response.json();
        setTradeDecision(decision);
      } catch (error) {
        console.error('Error fetching trade decision:', error);
        setTradeDecision(null);
      }
    };

    const fetchTradingPairs = async () => {
      try {
        const response = await fetch('https://fapi.binance.com/fapi/v1/exchangeInfo');
        if (!response.ok) {
          throw new Error('Failed to fetch trading pairs');
        }
        const data = await response.json();
        const pairs = data.symbols.map(symbol => symbol.symbol);
        setTradingPairs(pairs);
        console.log("here are the trading pairs", tradingPairs)

      } catch (error) {
        console.error('Error fetching trading pairs:', error);
        setTradingPairs([]);
      }
    };

    const fetchHistoricalData = async () => {
      try {
        const response = await fetch(process.env.NODE_ENV == 'production' ? `https://freedombot.online/api/historical-data/${selectedPair}` : `http://localhost:8001/api/historical-data/${selectedPair}`);
        if (!response.ok) {
          throw new Error('Failed to fetch historical data');
        }
        const data = await response.json();
      } catch (error) {
        console.error('Error fetching historical data:', error);
      }
    };

    if (isBotRunning && isConnected && api_Key && apiSecKey) {
      fetchAccountInfo();
      fetchTradeDecision();
      if (tradingManual === 'automatic') {
        fetchAutomaticTradingPairs()
      }
      else {

        fetchTradingPairs();
      }
      fetchHistoricalData();
      let intervalId;
      if (tradingManual == 'manual') {

        intervalId = setInterval(() => {
          fetchAccountInfo();
          fetchTradeDecision();


          fetchTradingPairs();

          fetchHistoricalData();
        }, 10000);
      }
      return () => {
        if (intervalId)
          clearInterval(intervalId);
      }

    }
  }, [isBotRunning, isConnected, apiKey, apiSecretKey, selectedPair, tradingManual]);

  useEffect(() => {
    if (tradeDecision && isBotRunning && isConnected && symbol
    ) {
      executeTrade();
    }
  }, [tradeDecision, isBotRunning, isConnected, symbol]);






  const executeTrade = async () => {
    if (tradeDecision === 'Buy') {

      try {
        setIsExecutingTrade(true);
        const response = await fetch(process.env.NODE_ENV == 'production' ? 'https://freedombot.online/api/execute-trade' : 'http://localhost:8001/api/execute-trade', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            apiKey: !api_Key.toString().trim() ? apiKey : api_Key,
            symbol,
            apiSecretKey: !apiSecKey?.toString().trim() ? apiSecretKey : apiSecKey,
            tradeDecision,
            orderType,
            selectedPair
          })
        });
        const data = await response.json();
        setTradeResult(data);
        setTradeResultVisible(true);
      } catch (error) {
        console.error('Error executing buy trade:', error);
      }
      finally {
        setIsExecutingTrade(false);
      }
    } else if (tradeDecision === 'Sell') {
      try {
        setIsExecutingTrade(true)
        const response = await fetch(process.env.NODE_ENV == 'production' ? 'https://freedombot.online/api/execute-trade' : 'http://localhost:8001/api/execute-trade', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            apiKey: !api_Key.toString().trim() ? apiKey : api_Key,
            symbol,
            apiSecretKey: !apiSecKey?.toString().trim() ? apiSecretKey : apiSecKey,
            tradeDecision,
            orderType,
            selectedPair
          })
        });
        const data = await response.json();
        setTradeResult(data);
        setTradeResultVisible(true);
      } catch (error) {
        console.error('Error executing sell trade:', error);
      }
      finally {
        setIsExecutingTrade(false);
      }
    }

  };

  // 

  
  
  
  


  return (
    <>
    <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-3">
      <div className='glass_morphism p-4'>
        <p className="text-white flex justify-center  font-semibold">FreeDom</p>
        <div className='flex flex-col gap-y-3'>
          <div className="flex flex-col gap-y-1">
            <p className="text-white text-sm font-normal text-start">API Key</p>
            <input type="text" value={!api_Key.toString().trim() ? apiKey : api_Key} onChange={(e) => {
              setApiKey(e.target.value)
            }} placeholder="Enter API Key" className="text-white border border-white p-1 px-4 outline-none bg-transparent rounded-md placeholder:text-white text-sm" />
          </div>
          <div className="flex flex-col gap-y-1">
            <p className="text-white text-sm font-normal text-start">API Secret Key</p>
            <input type="password" value={!apiSecKey?.toString().trim() ? apiSecretKey : apiSecKey} onChange={(e) => {
              setApiSecretKey(e.target.value)
            }} placeholder="Enter API Secret Key" className="text-white border border-white p-1 px-4 outline-none bg-transparent rounded-md placeholder:text-white text-sm" />
          </div>
          <div className="flex flex-col gap-y-1">
            <p className="text-white text-sm font-normal text-start" >Exchange Type:</p>
            <select className="text-white border border-white p-1 px-4 outline-none bg-transparent rounded-md placeholder:text-black text-sm" value={exchangeType} onChange={(e) => setExchangeType(e.target.value)}>
              <option className='bg-transparent text-black' value="spot">Binance Spot Trading</option>
              <option className='bg-transparent text-black' value="binanceFutures">Binance Futures Trading</option>
            </select>

          </div>
          <div className={`${apiSecKey && api_Key && 'flex gap-x-4'}`}>
            {
              apiSecKey && api_Key && (
                <button className='bg-white text-major-text-style w-[95%] md:w-[50%] mx-auto  rounded-xl p-2' onClick={handleClearKeys}>Clear Keys</button>
              )
            }
            {!isConnected ? (
              <button onClick={connectExchange} className='bg-white text-major-text-style w-[95%] md:w-[50%] mx-auto  rounded-xl p-2'>Connect Exchange</button>
            ) : (
              <>
                <button onClick={disconnectExchange} className='bg-white text-red-500 w-[95%] md:w-[50%] rounded-xl p-2 mx-auto'>Disconnect Exchange</button>
              </>
            )}

          </div>


          <div className='flex flex-col gap-y-1'>
            <p className='text-white  font-medium '>Bot Control</p>
            <div className='w-full flex flex-col md:flex-row justify-between gap-x-8 gap-y-2'>
              {!isBotRunning ? (
                <button className='bg-white text-major-text-style w-[95%] md:w-[50%] rounded-xl p-2' onClick={startBot}>Start Bot</button>
              ) : (
                <>
                  <button onClick={stopBot} className='bg-white text-red-500 w-[95%] md:w-[50%] rounded-xl p-2'>Stop Bot</button>
                </>
              )}
              <button className='bg-white text-major-text-style w-[95%] md:w-[50%] rounded-xl p-2' onClick={() => setShowAccountInfo(!showAccountInfo)}>
                {showAccountInfo ? 'Hide Account Info' : 'Show Account Info'}
              </button>

            </div>
            {tradeDecision && (
              <p className='text-green-500'>Trade Decision: {tradeDecision}</p>
            )}
            {tradeResultVisible && (
              <div className="flex flex-col">
                <p className='text-white font-medium'>Trade Result</p>
                <p className='text-white'>{JSON.stringify(tradeResult)}</p>
              </div>
            )}
          </div>
          {accId && <p className='text-green-500'>Connected to Binance (Account ID: {accId})</p>}
          {/* {!accId && <p>Connect to Binance</p>} */}
        </div>

      </div>


      <div className='glass_morphism p-4 flex flex-col gap-y-3'>
        <p className="text-white flex justify-center  font-semibold">Trading Options</p>
        <div className='flex items-center gap-x-8 justify-center'>
          <button className={` w-[85%] md:w-[40%] rounded-xl p-2 py-1 ${tradingManual == "automatic" ? 'bg-green-500 text-white' : 'bg-white text-major-text-style'}`} onClick={handleAutomaticTrade}>Automatic</button>
          <button className={` w-[85%] md:w-[40%] rounded-xl p-2 py-1 ${tradingManual == "manual" ? 'bg-green-500 text-white' : 'bg-white text-major-text-style'}`} onClick={handleManualTrade}>Manual</button>

        </div>
        <div className='flex flex-col gap-y-3'>
          <div className="flex flex-col gap-y-1">
            <p className="text-white text-sm font-normal text-start">Trading Pair</p>
            <select disabled={tradingManual === 'automatic'} className="text-white border border-white p-1 px-4 outline-none bg-transparent rounded-md placeholder:text-black text-sm" value={selectedPair} onChange={(e) => {
              setSelectedPair(e.target.value);
              setSymbol(e.target.value); // Update the symbol state
            }}>
              {tradingPairs.map(pair => (
                <option className='bg-transparent text-black' key={pair} value={pair}>{pair}</option>
              ))}
            </select>
          </div>
          <div className="flex flex-col gap-y-1">
            <p className="text-white text-sm font-normal text-start">Order Type</p>
            <select className="text-white border border-white p-1 px-4 outline-none bg-transparent rounded-md placeholder:text-black text-sm" value={orderType} onChange={(e) => setOrderType(e.target.value)}>
              {orderTypes.map(type => (
                <option className='bg-transparent text-black' key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>
          {
            (isExecutingTrade && isBotRunning) && (
              <p className='text-white animate-pulse'>Trade executing...</p>
            )}
          {(!isExecutingTrade && isBotRunning) && (
            <p className='text-green'>Trade execution stopped</p>
          )
          }

          {showAccountInfo && accountInfo && (
            <div className='flex flex-col text-white'>
              <h5 className='text-white font-bold'>Account Information</h5>
              {/* Placeholder for additional account information */}
              <p>Asset: <span className='text-green-500'>{accountInfo.asset}</span></p>
              <p>Wallet Balance:<span className='text-green-500'>{accountInfo.walletBalance}</span></p>
              <p>Unrealized Profit: <span className='text-green-500'>{accountInfo.unrealizedProfit}</span></p>
              <p>Margin Balance:<span className='text-green-500'>{accountInfo.marginBalance}</span> </p>
              <p>Position Initial Margin:<span className='text-green-500'>{accountInfo.positionInitialMargin}</span></p>
              <p>Open Order Initial Margin: <span className='text-green-500'>{accountInfo.openOrderInitialMargin}</span></p>
            </div>
          )}
        </div>
      </div>

    </div>

    <div className='glass_morphism p-4 flex flex-col gap-y-3 mt-4'>
        <p className="text-white flex justify-center  font-semibold">Open Orders</p>
        
        <div class="grid grid-cols-2 gap-4 ">
          <div className='grid text-white flex  font-semibold'>
         
          <button onClick={fetchOpenPositions} className='bg-white text-major-text-style w-[95%] md:w-[50%] mx-auto  rounded-xl p-2'>Open Positions</button>
          <div class="grid grid-cols-1 p-2 rounded ">
          {openPositions.length > 0 ? (
          <ul>
            {openPositions.map((position) => (
              <li key={position.symbol}>
                {position.symbol} - {position.positionAmt} - {position.unrealizedProfit}
                <button onClick={() => closePosition(position.symbol)}>Close Position</button>
              </li>
            ))}
          </ul>
        ) : (
          <p>No open positions.</p>
        )}
          </div>
          <button onClick={fetchOpenPositions} className='bg-white text-major-text-style w-[95%] md:w-[50%] mx-auto  rounded-xl p-2'>Close Position</button>  
          </div>
        
          <div className='grid text-white flex  font-semibold'>
          <button onClick={fetchOpenPositions} className='bg-white text-major-text-style w-[95%] md:w-[50%] mx-auto  rounded-xl p-2'>Open )Orders</button>
            <div class="grid grid-cols-1 p-2 rounded ">
            {openOrders.length > 0 ? (
          <ul>
            {openOrders.map((order) => (
              <li key={order.orderId}>
                {order.symbol} - {order.side} - {order.status}
              </li>
            ))}
          </ul>
        ) : (
          <p>No open orders.</p>
        )}
          <button onClick={closeAllOpenOrders} className='bg-white text-major-text-style w-[95%] md:w-[50%] mx-auto  rounded-xl p-2'>Close Orders</button>
          </div>
          </div>
        </div>

      </div>

</>

  );
}

export default withAuth(Trade);
